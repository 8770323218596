import { Flex, HStack, Text, Box } from "@chakra-ui/react";
import ImageWithFallback from "@components/ImageWithFallback";
import { TenantThemeContext } from "@contexts/tenantTheme/TenantThemeContext";
import useTranslation from "@hooks/useTranslation";
import Image from "next/image";
import Link from "next/link";
import { useContext } from "react";
import fallbackImg from "../../../public/login-assets/hermesloginimage.png";

const AuthLayout = ({ children }) => {
    const { locale, t } = useTranslation("login");

    const { authImage } = useContext(TenantThemeContext);
    return (
        <Flex minH="100vh" bg="white" w="full">
            <HStack
                overflow="hidden"
                justify="start"
                w="50%"
                display={{ base: "none", lg: "flex" }}
            >
                <Box
                    position="relative"
                    w="full"
                    h="full"
                    sx={{ img: { objectFit: "cover" } }}
                >
                    <ImageWithFallback
                        alt="Login Image"
                        src={authImage}
                        fallback={fallbackImg}
                        fill
                        quality={100}
                        priority
                    />
                </Box>
            </HStack>
            <Flex
                px={{ base: "8", lg: "12" }}
                py="16"
                bg="white"
                w={{ base: "full", lg: "50%" }}
                direction="column"
                align="center"
            >
                <Flex
                    h="full"
                    maxW="md"
                    w="full"
                    direction="column"
                    align="start"
                    gap="3"
                >
                    <HStack mt={"-5"} spacing={4}>
                        <Text
                            fontSize={{ base: "xs", xl: "md" }}
                            color={locale === "en" ? "black" : "gray.400"}
                            _hover={{ textDecoration: "underline" }}
                        >
                            <Link href="/auth/login" locale="en">
                                {t("ENGLISH")}
                            </Link>
                        </Text>
                        <Text
                            ml={"3"}
                            fontSize={{ base: "xs", xl: "md" }}
                            color={locale === "es" ? "black" : "gray.400"}
                            _hover={{ textDecoration: "underline" }}
                        >
                            <Link href="/auth/login" locale="es">
                                {t("SPANISH")}
                            </Link>
                        </Text>
                    </HStack>
                    <Flex
                        direction="column"
                        grow={1}
                        w="full"
                        justify="start"
                        align="center"
                        pt={{ base: "7", xl: "51" }}
                    >
                        {children}
                    </Flex>
                    <HStack w="full" justify="center">
                        <Image
                            alt="Hermes Logo"
                            src="/login-assets/hermes.svg"
                            width="140"
                            height="28"
                        />
                    </HStack>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default AuthLayout;
