import React, { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import schema from "@schemas/two-fa";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Stack, Text } from "@chakra-ui/react";
import FormPinInput from "./FormPinInput";
import AlertValidationErrors from "@components/AlertValidationErrors";
import useTranslation from "@hooks/useTranslation";

interface TwoFAFormProps {
    onSubmit: (values: FieldValues) => void;
    isLoading: boolean;
    errors: string[];
}

export const TwoFAForm = ({ onSubmit, isLoading, errors }: TwoFAFormProps) => {
    const { t } = useTranslation("login");

    const [useRecoveryCode] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors: formErrors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    return (
        <Stack
            spacing={4}
            w={"full"}
            as="form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <Stack mt={5}>
                <Text fontSize="sm" color="text.1200" mt={19} mb={19}>
                    {t("PLEASE_CHECK")}
                </Text>
            </Stack>

            <FormPinInput
                id="componentIds"
                error={formErrors?.code}
                label={
                    useRecoveryCode
                        ? "Recovery"
                        : t("VERIFICATION_CODE_MESSAGE")
                }
                type="text"
                name="code"
                placeholder={`Enter${useRecoveryCode ? "Recovery" : ""} Code`}
                control={control}
            />

            {isLoading ? (
                <AlertValidationErrors
                    width="full"
                    backgroundColor={"alert.200"}
                    fontColor={"alert.150"}
                    borderColor={"alert.150"}
                    alertMessage={"Your code is verified!"}
                    errors={errors}
                />
            ) : (
                <AlertValidationErrors
                    width="full"
                    backgroundColor={"alert.50"}
                    fontColor={"alert.100"}
                    borderColor={"alert.100"}
                    alertMessage={
                        "Error: The validation code doesn&apos;t match."
                    }
                    errors={errors}
                />
            )}

            <Button type="submit" isLoading={isLoading}>
                {t("VERIFY")}
            </Button>
        </Stack>
    );
};
