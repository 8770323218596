import { Button, Stack, Text, Switch, Flex } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { FieldValues, useForm } from "react-hook-form";
import schema from "@schemas/login";
import Link from "next/link";
import FormInput from "./FormInput";
import AlertValidationErrors from "@components/AlertValidationErrors";
import useTranslation from "@hooks/useTranslation";

interface LoginFormProps {
    onSubmit: (values: FieldValues) => void;
    isLoading: boolean;
    errors: string[];
}

export const LoginForm = ({ onSubmit, isLoading, errors }: LoginFormProps) => {
    const { t } = useTranslation("login");

    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    return (
        <>
            <Stack
                w="full"
                mt={5}
                as="form"
                spacing="4"
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormInput
                    size="md"
                    register={register}
                    label={t("USERNAME")}
                    error={formErrors?.username}
                    type="text"
                    name="username"
                    placeholder={t("USERNAME")}
                />

                <FormInput
                    size="md"
                    register={register}
                    label={t("PASSWORD")}
                    error={formErrors?.password}
                    name="password"
                    placeholder={t("PASSWORD")}
                    type="password"
                />
                <Flex flexDir={"column"} my="3">
                    {/* Validation Errors */}
                    <AlertValidationErrors
                        width="full"
                        backgroundColor={"alert.50"}
                        color={"alert.100"}
                        borderColor={"alert.100"}
                        alertMessage="Error: These credentials do not match our records."
                        errors={errors}
                    />
                    <Stack
                        mt={"4"}
                        direction="row"
                        align="start"
                        color="text.1200"
                        w={"full"}
                    >
                        <Switch mt={"1"} size="md" id="rememberMe"></Switch>
                        <Text fontSize={"sm"} pt={"0.5"}>
                            {t("REMEMBER_ME")}
                        </Text>
                    </Stack>

                    <Button
                        mt={"4"}
                        type="submit"
                        variant={"solid"}
                        isLoading={isLoading}
                    >
                        {t("SIGN_IN")}
                    </Button>

                    <Text
                        mt={"3"}
                        fontSize={"sm"}
                        color="primary.300"
                        _hover={{ textDecoration: "underline" }}
                    >
                        <Link href="/auth/forgot-password">
                            {t("FORGOT_PASSWORD")}
                        </Link>
                    </Text>
                </Flex>
            </Stack>
        </>
    );
};
